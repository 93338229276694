import { connect } from 'react-redux';
import { testimonialSet, testimonialReset } from '../actions';
import { DEFAULT_TESTIMONIAL } from '../reducers';

const mapDispatchToProps = (dispatch) => ({
  setTestimonial: (data) => {
    dispatch(testimonialSet(data));
  },
  resetTestimonial: () => {
    dispatch(testimonialReset());
  }
});

const mapStateToProps = (state) => ({
  testimonial: state.testimonial ? state.testimonial : DEFAULT_TESTIMONIAL
});

export default connect(mapStateToProps, mapDispatchToProps);
