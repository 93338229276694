import { connect } from 'react-redux';
import { referralSet, referralReset } from '../actions';
import { DEFAULT_REFERRAL } from '../reducers';

const mapDispatchToProps = (dispatch) => ({
  setReferral: (data) => {
    dispatch(referralSet(data));
  },
  resetReferral: () => {
    dispatch(referralReset());
  }
});

const mapStateToProps = (state) => ({
  referral: state.referral ? state.referral : DEFAULT_REFERRAL
});

export default connect(mapStateToProps, mapDispatchToProps);
