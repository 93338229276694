import React from 'react';

const Modal = ({
  modal,
  positiveButton,
  negativeButton,
  content,
  contentClass,
  icon,
  customContent,
  customContentClassName,
  onPositiveClick,
  onNegativeClick,
  customPadding,
  onOutsideClick
}) =>
  modal && (
    <div
      className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-25 z-50 h-[100vh] transition-all"
      onClick={onOutsideClick}>
      <div
        className={
          customContent
            ? customContentClassName
            : 'w-9/12 max-w-[350px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[7px] transition-all'
        }>
        <div className={`bg-white rounded-[5px] overflow-hidden ${customPadding ? 'p-4' : ''}`}>
          {content && (
            <div className="flex flex-col gap-2.5 items-center rounded-b-none rounded-[5px] bg-transparent">
              {icon}
              <div
                className={`max-w-[230px] mx-auto rounded-md text-center font-medium text-sm pb-5 ${contentClass}`}>
                <p>{content}</p>
              </div>
            </div>
          )}

          {customContent && (
            <div className="bg-white rounded-[7px] border-b-[0.25px] border-[#C6C6C8] text-[#5046E4] text-[20px]">
              {customContent}
            </div>
          )}
          <div
            className={`flex flex-row gap-2 overflow-hidden ${
              positiveButton && negativeButton ? 'rounded-[5px]' : 'rounded-[5px]'
            }`}>
            {positiveButton && (
              <button
                className="w-full bg-[#5046E4] rounded-md text-sm text-white py-2"
                type="button"
                onClick={onPositiveClick}>
                {positiveButton}
              </button>
            )}
            {negativeButton && (
              <button
                className="w-full text-sm bg-[#E4E7EB] text-black text-opacity-80 rounded-md py-2"
                type="button"
                onClick={onNegativeClick}>
                {negativeButton}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );

export default Modal;
