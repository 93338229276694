import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import formatString from 'format-string-by-pattern';
import { isEmpty } from 'lodash';
import {
  composeValidators,
  email,
  renderField,
  required,
  usPhoneNumber
} from '../helpers/form-validations';
import Header from '../components/Header';
import withCustomer from '../redux/Hoc/withCustomer';
import { checkPermission } from '../helpers/app';
import withUser from '../redux/Hoc/withUser';

const CustomerInfo = ({ user, customer, setCustomer }) => {
  const navigate = useNavigate();

  const onSubmit = (formData, form) => {
    setCustomer({ ...formData, email: formData.email || '' });
    form.reset();
    if (isEmpty(formData.email)) {
      navigate('/missing-email', { replace: true });
    } else {
      navigate('/review', { replace: true });
    }
  };

  useEffect(() => {
    checkPermission(user, navigate);
  }, []);

  return (
    <div className="relative w-full max-w-screen-md mx-auto">
      <Header
        title="Customer"
        subtitle="Enter customer information"
        onBackClick={() => navigate('/select-photo', { replace: true })}
      />
      <div className="flex flex-col gap-[30px] px-5 pt-6">
        <Form
          onSubmit={onSubmit}
          initialValues={customer}
          validate={(values) => {
            const errors = {};
            if (isEmpty(values.phone) || isEmpty(values.email)) {
              if (isEmpty(values.email)) {
                errors.email = 'Required';
              }
              if (isEmpty(values.phone)) {
                errors.phone = 'Required';
              }
            }
            if (!isEmpty(values.email) || !isEmpty(values.phone)) {
              errors.email = undefined;
              errors.phone = undefined;
            }
            return errors;
          }}
          render={({ values, handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <div className=" flex flex-col gap-y-5 pb-6">
                <div className="flex flex-col gap-2">
                  <label
                    className={`text-base font-medium ${
                      touched.fname && errors.fname && 'text-red-500'
                    }`}>
                    First Name or Nickname *
                  </label>
                  <Field
                    type="text"
                    name="fname"
                    component="input"
                    className={`rounded-md border px-4 py-4 focus-visible:outline-none ${
                      touched.fname && errors.fname
                        ? 'border-red-500'
                        : 'border-[#555555] border-opacity-40'
                    }`}
                    showErrorMsg={false}
                    placeholder="James"
                    validate={composeValidators(required)}>
                    {renderField}
                  </Field>
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    className={`text-base font-medium ${
                      touched.lname && errors.lname && 'text-red-500'
                    }`}>
                    Last Name *
                  </label>
                  <Field
                    type="text"
                    name="lname"
                    component="input"
                    className={`rounded-md border px-4 py-4 focus-visible:outline-none ${
                      touched.lname && errors.lname
                        ? 'border-red-500'
                        : 'border-[#555555] border-opacity-40'
                    }`}
                    showErrorMsg={false}
                    placeholder="Petter"
                    validate={composeValidators(required)}>
                    {renderField}
                  </Field>
                </div>
                <div className="flex flex-col gap-2">
                  <label className="text-base font-medium">Second Last Name</label>
                  <Field
                    type="text"
                    name="second_lname"
                    component="input"
                    className="rounded-md border border-[#555555] border-opacity-40 px-4 py-4 focus-visible:outline-none"
                    placeholder="Leyton">
                    {renderField}
                  </Field>
                </div>
                <div className="flex flex-col gap-2">
                  <label className="text-base font-medium">Spouse/Partner</label>
                  <Field
                    type="text"
                    name="co_buyer_fname"
                    component="input"
                    className="rounded-md border border-[#555555] border-opacity-40 px-4 py-4 focus-visible:outline-none"
                    placeholder="Mary">
                    {renderField}
                  </Field>
                </div>
                <div>
                  <div className="flex-1 h-0.5 bg-gray-200" />
                  <label className={`flex mt-5 ${touched.email && errors.email && 'text-red-500'}`}>
                    At least one is required:
                  </label>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-2">
                    <label
                      className={`text-base font-medium ${
                        touched.email && errors.email && 'text-red-500'
                      }`}>
                      Email
                    </label>
                    <Field
                      type="text"
                      name="email"
                      component="input"
                      className={`rounded-md border px-4 py-4 focus-visible:outline-none ${
                        touched.email && errors.email
                          ? 'border-red-500'
                          : 'border-[#555555] border-opacity-40'
                      }`}
                      showErrorMsg={errors.email}
                      placeholder="jamespeterleyton54@gmail.com"
                      validate={composeValidators(email)}>
                      {renderField}
                    </Field>
                  </div>
                  <div className="flex flex-row gap-2 items-center justify-center">
                    <div className="flex-1 h-0.5 bg-gray-200" />
                    <label className="flex mt-1">OR</label>
                    <div className="flex-1 h-0.5 bg-gray-200" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label
                      className={`text-base font-medium ${
                        touched.phone && errors.phone && 'text-red-500'
                      }`}>
                      Phone
                    </label>
                    <Field
                      type="text"
                      name="phone"
                      component="input"
                      className={`rounded-md border px-4 py-4 focus-visible:outline-none ${
                        touched.phone && errors.phone
                          ? 'border-red-500'
                          : 'border-[#555555] border-opacity-40'
                      }`}
                      showErrorMsg={errors.phone}
                      placeholder="(402) 555-1212"
                      parse={formatString('(402) 555-1212')}
                      validate={composeValidators(usPhoneNumber)}>
                      {renderField}
                    </Field>
                  </div>
                </div>
                <button
                  type="submit"
                  className="w-full text-lg text-white bg-[#5046E4] rounded-md py-4">
                  Submit
                </button>
                {!customer.dvReceived && (
                  <button
                    type="button"
                    className="w-full text-lg bg-[#E4E7EB] text-black text-opacity-80 rounded-md py-4"
                    onClick={() => {
                      if (!isEmpty(values.email) || !isEmpty(values.phone)) {
                        if (isEmpty(errors.email) && isEmpty(errors.phone)) {
                          setCustomer(values);
                          navigate('/review', { replace: true });
                        }
                      } else {
                        setCustomer(values);
                        navigate('/review', { replace: true });
                      }
                    }}>
                    Later
                  </button>
                )}
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default withUser(withCustomer(CustomerInfo));
