/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VideoCameraIcon } from '@heroicons/react/solid';
import { isEmpty } from 'lodash';
import CloseButton from '../assets/image/icons/close-button.svg';
import Header from '../components/Header';
import { callFBFunction, deleteFile, uploadFile } from '../utils/firebase';
import withNotify from '../redux/Hoc/withNotify';
import withLoader from '../redux/Hoc/withLoader';
import Modal from '../components/Modal';
import { checkPermission } from '../helpers/app';
import withUser from '../redux/Hoc/withUser';
import withTestimonial from '../redux/Hoc/withTestimonial';

const SelectVideo = ({ user, setLoader, testimonial, setNotify }) => {
  const inputRef = useRef();
  const navigate = useNavigate();
  const [video, setVideo] = useState(null);
  const [file, setFile] = useState(null);
  const [modal, setModal] = useState(false);

  const getVideo = () => {
    inputRef.current.click();
  };

  const deleteImage = () => {
    setVideo('');
    setModal(false);
  };

  useEffect(() => {
    checkPermission(user, navigate);
  }, []);

  const validateFile = (videoFile) => {
    const allowedTypes = [
      'video/mp4',
      'video/webm',
      'video/ogg',
      'video/avi',
      'video/mkv',
      'video/mov',
      'video/quicktime',
      'video/x-msvideo', // For AVI files
      'video/x-matroska', // For MKV files
      'video/3gpp', // For 3GP files
      'video/3gp2' // For 3G2 files
    ];
    const maxSize = 400 * 1024 * 1024; // 400MB
    const maxDuration = 2 * 60; // 2 minutes in seconds

    if (!allowedTypes.includes(videoFile.type)) {
      return 'Invalid file type. Please upload a video with one of the following formats: MP4, WebM, OGG, AVI, MKV, MOV, QuickTime, 3GP, 3G2.';
    }

    if (videoFile.size > maxSize) {
      return 'File size exceeds 400MB.';
    }

    return new Promise((resolve) => {
      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(videoFile);
      videoElement.onloadedmetadata = () => {
        URL.revokeObjectURL(videoElement.src);
        if (videoElement.duration > maxDuration) {
          resolve('Video duration exceeds 2 minutes.');
        } else {
          setVideo(URL.createObjectURL(videoFile));
          resolve('');
        }
      };
      videoElement.onerror = () => {
        URL.revokeObjectURL(videoElement.src);
        resolve('Error loading video metadata.');
      };
    });
  };

  const uploadVideo = async (e) => {
    setFile(e.target.files[0]);
    const error = await validateFile(e.target.files[0]);
    if (!isEmpty(error)) {
      setNotify({
        message: error,
        type: 'error'
      });
    }
  };

  const handleSubmit = () => {
    const path = 'owner_testimonials';
    setLoader(true);
    uploadFile(path, file)
      .then((res) => {
        if (res) {
          callFBFunction('addTestimonial', { url: res, ownerId: testimonial.owner_id })
            .then((response) => {
              setLoader(false);
              if (response.data.status) {
                navigate('/success-testimonial', {
                  replace: true
                });
                return;
              }
              setNotify({ message: response.data.message, type: 'error' });
            })
            .catch(() => setLoader(false));
        }
      })
      .catch(() => {
        setLoader(false);
        setVideo(null);
        setNotify({ message: 'Error uploading the video. Please try again.', type: 'error' });
      });
  };

  return (
    <>
      <div className="relative w-full max-w-screen-md mx-auto">
        <Header
          title="Video Testimonial"
          subtitle={video ? 'Approve Video' : 'Upload Video'}
          onBackClick={() => {
            navigate('/owners', { replace: true });
          }}
        />
        <div className="w-full px-5">
          <div className="flex flex-col gap-2 my-6">
            <div className="flex flex-row justify-between">
              <input
                type="file"
                accept="video/*"
                className="hidden"
                ref={inputRef}
                onInput={(e) => {
                  uploadVideo(e);
                  e.target.value = '';
                }}
              />
            </div>
            <div className="relative w-full h-[235px] bg-white rounded-md p-2.5">
              {video ? (
                <>
                  <button
                    type="button"
                    className="absolute w-5 aspect-square top-1 right-1 z-10"
                    onClick={() => setModal(true)}>
                    <img src={CloseButton} alt="close button" />
                  </button>
                  <video
                    className="relative w-full h-full rounded-md object-center object-cover"
                    controls>
                    <source src={video} />
                  </video>
                </>
              ) : (
                <button
                  type="button"
                  className="absolute top-0 bottom-0 left-0 right-0 m-auto inline-flex flex-col gap-2 items-center justify-center text-base text-[#5046E4] font-medium"
                  onClick={getVideo}>
                  <VideoCameraIcon width={30} /> Add Video
                </button>
              )}
            </div>
            <p className="text-center text-[12px]">
              Try to limit the video to less than 2 minutes and 400mb
            </p>
          </div>
          <button
            disabled={!video}
            type="button"
            className={`w-full rounded-md text-lg py-4 transition-all ${
              video ? 'bg-[#5046E4] text-[#FFFFFF]' : 'bg-[#E4E7EB] text-[#C9C8E3]'
            }`}
            onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
      <Modal
        modal={modal}
        customPadding
        content="Are you sure you want to choose or record another testimonial video?"
        positiveButton="Yes"
        negativeButton="No, cancel"
        onPositiveClick={() => deleteImage()}
        onNegativeClick={() => setModal(false)}
      />
    </>
  );
};

export default withLoader(withNotify(withUser(withTestimonial(SelectVideo))));
