import React from 'react';
import { trim } from 'lodash';

const FormField = (props) => {
  const { input, meta, onDropDownChange = () => {}, showErrorMsg = true, ...rest } = props;

  const dropDownChange = (event) => {
    const {
      input: { name }
    } = props;
    if (onDropDownChange) {
      onDropDownChange({ name, value: event.target.value });
    }
  };

  return (
    <>
      {input.type === 'dropdown' ? (
        <select {...rest} {...input} {...(onDropDownChange ? { onChange: dropDownChange } : {})}>
          {rest.placeholder && <option value="">{rest.placeholder}</option>}
          {rest.data.map((value, key) => (
            <option key={`reduxDropdown${key}`}>{value}</option>
          ))}
        </select>
      ) : null}

      {input.type === 'text' || input.type === 'password' || input.type === 'checkbox' ? (
        <input {...rest} {...input} type={input.type} />
      ) : input.type === 'textarea' ? (
        <textarea {...rest} {...input} />
      ) : null}

      {showErrorMsg && meta.touched && meta.error && (
        <p className="text-red-500 text-xs italic text-right">&nbsp;{meta.error}</p>
      )}
    </>
  );
};

export const renderField = (props) => <FormField {...props} />;

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

export const required = (value) => (trim(value) ? undefined : 'Required');

export const maxLength = (max) => (value) =>
  value && value.length >= max ? `Maximum ${max} characters are allowed.` : undefined;

export const maxValue = (max) => (value) =>
  value && value >= max ? `Must be ${max} or less` : undefined;

export const maxValue100 = maxValue(100);

export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const number = (value) => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);

export const numberReg = /^\d+$/;

export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const usPhoneNumber = (value) =>
  value && !/^\(\d{3}\)\s?\d{3}-\d{4}$/i.test(value) ? 'Invalid phone' : undefined;

export const passwordsMustMatch = (value, allValues) =>
  value !== allValues.password ? 'Passwords do not match' : undefined;
