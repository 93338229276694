import React from 'react';
import Modal from './Modal';

const FilterModal = ({ modal, onClick, onOutsideClick }) => (
  <Modal
    modal={modal}
    customContentClassName="absolute bottom-2 left-2 right-2 rounded-[7px] transition-all"
    customContent={
      <div>
        <p className="text-center py-3 text-xl font-bold text-white bg-[#5046E4] border-b-1 border-[#C6C6C8]">
          Sort By
        </p>
        {['model', 'make', 'vin', 'year'].map((item, index) => (
          <div className="" key={index} onClick={() => onClick(item)}>
            <p className="text-center py-2 text-md text-[#5046E4] border-b-[0.25px] border-[#C6C6C8]">
              {item.toUpperCase()}
            </p>
          </div>
        ))}
      </div>
    }
    onOutsideClick={onOutsideClick}
  />
);

export default FilterModal;
