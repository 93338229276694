import { isEmpty } from 'lodash';
import { notifySet, resetRedux, customerReset, referralReset } from '../redux/actions';
import { store } from '../redux/store';
import { doAuthLogout, getCurrentUser } from '../utils/firebase';

export const userLogout = async () => {
  setTimeout(() => {
    doAuthLogout();
    sessionStorage.clear();
    store.dispatch(resetRedux());
    store.dispatch(customerReset());
    store.dispatch(notifySet({ type: 'success', message: 'You have successfully signed out.' }));
  }, 100);
};

export const checkPermission = async (user, navigate) => {
  setTimeout(() => {
    if (!isEmpty(user.id)) {
      const currentUser = getCurrentUser();
      if (!currentUser) {
        doAuthLogout();
        sessionStorage.clear();
        store.dispatch(resetRedux());
        store.dispatch(customerReset());
        store.dispatch(referralReset());
        store.dispatch(notifySet({ type: 'error', message: 'Your session has been expired.' }));
        navigate('/login', {
          replace: true
        });
      }
    } else {
      navigate('/login', {
        replace: true
      });
    }
  }, 2000);
};

export const getImgDimension = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  });

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'capitalize', {
  value() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

export const filterByValue = (array, string) =>
  array.filter((data) => JSON.stringify(data).toLowerCase().indexOf(string.toLowerCase()) !== -1);
