import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SuccessIcon from '../../assets/image/icons/success-icon.svg';
import Header from '../../components/Header';
import { checkPermission } from '../../helpers/app';
import withUser from '../../redux/Hoc/withUser';

const SuccessTestimonial = ({ user }) => {
  const navigate = useNavigate();
  const clearInformation = () => {
    navigate('/owners', {
      replace: true
    });
  };

  useEffect(() => {
    checkPermission(user, navigate);
  }, []);

  return (
    <div className="relative w-full max-w-screen-md mx-auto">
      <Header showBack={false} />
      <div className="absolute top-0 w-full min-h-screen flex flex-col flex-1 p-5">
        <div className="flex flex-1 flex-col items-center justify-center">
          <h1 className="text-2xl font-semibold text-center">Video Uploaded!</h1>
          <img className="w-16 h-16 mt-10" src={SuccessIcon} alt="success" />
          <div className="text-center text-base text-normal text-[#333333] px-7 pt-20">
            <p>Great Work</p>
            <p>You've successfully uploaded your customers video testimonial!</p>
          </div>
        </div>
        <div className="flex flex-initial flex-col gap-5">
          <button
            type="submit"
            className="w-full bg-[#5046E4] rounded-md text-lg text-white py-4"
            onClick={clearInformation}>
            Upload Another Video
          </button>
          <button
            type="submit"
            className="w-full text-lg bg-[#E4E7EB] text-black text-opacity-80 rounded-md py-4"
            onClick={() => navigate('/inventory', { replace: true })}>
            Go to Inventory
          </button>
        </div>
      </div>
    </div>
  );
};

export default withUser(SuccessTestimonial);
