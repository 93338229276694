import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import SuccessIcon from '../../assets/image/icons/success-icon.svg';
import Header from '../../components/Header';
import { checkPermission } from '../../helpers/app';
import withCustomer from '../../redux/Hoc/withCustomer';
import withUser from '../../redux/Hoc/withUser';

const Success = ({ user, customer }) => {
  const navigate = useNavigate();
  const clearInformation = () => {
    navigate('/inventory', {
      replace: true
    });
  };

  const getContentOne = () => {
    let content = '';
    if (customer) {
      if (
        !isEmpty(customer.fname) &&
        !isEmpty(customer.lname) &&
        (!isEmpty(customer.email) || !isEmpty(customer.phone))
      ) {
        content = 'Great work!';
      }
    }
    return content;
  };
  const getContentTwo = () => {
    let content = '';
    if (customer) {
      if (
        !isEmpty(customer.fname) &&
        !isEmpty(customer.lname) &&
        (!isEmpty(customer.email) || !isEmpty(customer.phone)) &&
        !customer.onBoard
      ) {
        content =
          "You've successfully created a vinsyt for your customer and we will post it to facebook if permission was given.";
      } else if (customer.onBoard) {
        content =
          "We've recived your delivery photo! We'll add it to your customer's vinsyt and post it to facebook if permission was given.";
      } else {
        content =
          'Thank you for the delivery photo. When the DMS records are sold vehicle associated with this VIN under your account, we will use that information to create a vinsyt.';
      }
    }
    return content;
  };

  useEffect(() => {
    checkPermission(user, navigate);
  }, []);

  return (
    <div className="relative w-full max-w-screen-md mx-auto">
      <Header showBack={false} />
      <div className="absolute top-0 w-full min-h-screen flex flex-col flex-1 p-5">
        <div className="flex flex-1 flex-col items-center justify-center">
          <h1 className="text-2xl font-semibold">Success</h1>
          <img className="w-16 h-16 mt-10" src={SuccessIcon} alt="success" />
          <div className="text-center text-base text-normal text-[#333333] px-7 pt-20">
            <p>{getContentOne()}</p>
            <p>{getContentTwo()}</p>
          </div>
        </div>
        <div className="flex flex-initial">
          <button
            type="submit"
            className="w-full bg-[#5046E4] rounded-md text-lg text-white py-4 mt-16"
            onClick={clearInformation}>
            Go to Inventory
          </button>
        </div>
      </div>
    </div>
  );
};

export default withUser(withCustomer(Success));
