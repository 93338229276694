import { connect } from 'react-redux';
import { customerSet, customerReset } from '../actions';
import { DEFAULT_CUSTOMER } from '../reducers';

const mapDispatchToProps = (dispatch) => ({
  setCustomer: (data) => {
    dispatch(customerSet(data));
  },
  resetCustomer: () => {
    dispatch(customerReset());
  }
});

const mapStateToProps = (state) => ({
  customer: state.customer ? state.customer : DEFAULT_CUSTOMER
});

export default connect(mapStateToProps, mapDispatchToProps);
