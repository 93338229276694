import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusCircleIcon } from '@heroicons/react/solid';
import CloseButton from '../assets/image/icons/close-button.svg';
import Header from '../components/Header';
import { deleteFile, uploadFile } from '../utils/firebase';
import withCustomer from '../redux/Hoc/withCustomer';
import withNotify from '../redux/Hoc/withNotify';
import withLoader from '../redux/Hoc/withLoader';
import Modal from '../components/Modal';
import { checkPermission, getImgDimension } from '../helpers/app';
import withUser from '../redux/Hoc/withUser';

const SelectPhoto = ({ user, setLoader, setNotify, setCustomer, customer }) => {
  const inputRef = useRef();
  const navigate = useNavigate();
  const [image, setImage] = useState(customer.delivery_photo);
  const [modal, setModal] = useState(false);

  const getImage = () => {
    inputRef.current.click();
  };

  const deleteImage = () => {
    setLoader(true);
    deleteFile(image)
      .then(() => {
        setLoader(false);
        setModal(false);
        setImage('');
        setCustomer({ delivery_photo: '' });
      })
      .catch((err) => {
        setLoader(false);
        setNotify({
          message: err,
          type: 'error'
        });
      });
  };

  useEffect(() => {
    checkPermission(user, navigate);
  }, []);

  const uploadImage = async (e) => {
    const file = e.target.files[0];

    const imgDimension = await getImgDimension(file);

    if (imgDimension.width < imgDimension.height || imgDimension.width === imgDimension.height) {
      // it's a portrait or square. show error message.
      setNotify({
        message:
          'Only landscape-oriented photo can be uploaded. Please retake your photo accordingly.',
        type: 'error'
      });
      return false;
    }

    setLoader(true);

    const path = 'sa_mobile';
    const fileName = file.name;

    const idxDot = fileName.lastIndexOf('.') + 1;
    const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png' || extFile === 'webp') {
      setImage(URL.createObjectURL(file));

      uploadFile(path, file)
        .then((res) => {
          setLoader(false);
          setImage(res);
          setCustomer({ delivery_photo: res });
        })
        .catch(() => {
          setLoader(false);
          setNotify({ message: 'Error uploading the image. Please try again.', type: 'error' });
        });
    } else {
      setLoader(false);
      setNotify({
        message:
          'Unsupported image type. System will support allowed only JPG, JPEG, PNG and WEBP.',
        type: 'error'
      });
    }
    return true;
  };

  return (
    <>
      <div className="relative w-full max-w-screen-md mx-auto">
        <Header
          title="Delivery Photo"
          subtitle={image ? 'Approve Photo' : 'Select Photo'}
          onBackClick={() => {
            navigate('/inventory', { replace: true });
          }}
        />
        <div className="w-full px-5">
          <div className="flex flex-col gap-2 my-6">
            <div className="flex flex-row justify-between">
              <input
                type="file"
                accept="image/*"
                className="hidden"
                ref={inputRef}
                onInput={(e) => {
                  uploadImage(e);
                  e.target.value = '';
                }}
              />
            </div>
            <div className="relative w-full h-[235px] bg-white rounded-md p-2.5">
              {image ? (
                <>
                  <button
                    type="button"
                    className="absolute w-5 aspect-square top-1 right-1 z-10"
                    onClick={() => setModal(true)}>
                    <img src={CloseButton} alt="close button" />
                  </button>
                  <img
                    className="relative w-full h-full rounded-md object-center object-cover"
                    src={image}
                    alt="delivery"
                  />
                </>
              ) : (
                <button
                  type="button"
                  className="absolute top-0 bottom-0 left-0 right-0 m-auto inline-flex flex-col gap-2 items-center justify-center text-base text-[#5046E4] font-medium"
                  onClick={getImage}>
                  <PlusCircleIcon width={30} /> Add Photo
                </button>
              )}
            </div>
          </div>
          <button
            disabled={!image}
            type="button"
            className={`w-full rounded-md text-lg py-4 transition-all ${
              image ? 'bg-[#5046E4] text-[#FFFFFF]' : 'bg-[#E4E7EB] text-[#C9C8E3]'
            }`}
            onClick={() => {
              navigate('/customer-info', {
                replace: true
              });
            }}>
            Next Step
          </button>
        </div>
      </div>
      <Modal
        modal={modal}
        customPadding
        content="Are you sure you want to select a new delivery photo?"
        positiveButton="Yes"
        negativeButton="No, cancel"
        onPositiveClick={() => deleteImage()}
        onNegativeClick={() => setModal(false)}
      />
    </>
  );
};

export default withLoader(withNotify(withUser(withCustomer(SelectPhoto))));
