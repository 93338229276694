export const RESET_REDUX = 'redux/RESET';
export const LOADER_SET = 'loader/SET';
export const NOTIFY_SET = 'notify/SET';
export const USER_SET = 'user/SET';
export const CUSTOMER_SET = 'customer/SET';
export const RESET_CUSTOMER = 'customer/RESET';
export const REFERRAL_SET = 'referral/SET';
export const RESET_REFERRAL = 'referral/RESET';
export const TESTIMONIAL_SET = 'testimonial/SET';
export const RESET_TESTIMONIAL = 'testimonial/RESET';
export const CHANGE_PASSWORD_SET = 'changePassword/SET';
export const RESET_CHANGE_PASSWORD = 'changePassword/RESET';

export const resetRedux = () => ({
  type: RESET_REDUX
});

export const loaderSet = (data) => ({
  type: LOADER_SET,
  data
});

export const notifySet = (state) => ({
  type: NOTIFY_SET,
  state
});

export const userSet = (user) => ({
  type: USER_SET,
  user
});

export const customerSet = (action) => ({
  type: CUSTOMER_SET,
  payload: action
});

export const customerReset = () => ({
  type: RESET_CUSTOMER
});

export const referralSet = (action) => ({
  type: REFERRAL_SET,
  payload: action
});

export const referralReset = () => ({
  type: RESET_REFERRAL
});

export const testimonialSet = (action) => ({
  type: TESTIMONIAL_SET,
  payload: action
});

export const testimonialReset = () => ({
  type: RESET_TESTIMONIAL
});

export const changePasswordSet = (action) => ({
  type: CHANGE_PASSWORD_SET,
  payload: action
});

export const changePasswordReset = () => ({
  type: RESET_CHANGE_PASSWORD
});
