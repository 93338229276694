/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { isEmpty } from 'lodash';
import Header from '../../components/Header';
import { composeValidators, required } from '../../helpers/form-validations';
import { callFBFunction } from '../../utils/firebase';
import withLoader from '../../redux/Hoc/withLoader';
import withNotify from '../../redux/Hoc/withNotify';
import withCustomer from '../../redux/Hoc/withCustomer';
import { checkPermission } from '../../helpers/app';
import withUser from '../../redux/Hoc/withUser';

const AddVehicle = ({ user, setLoader, setNotify, setCustomer, resetCustomer, customer }) => {
  const navigate = useNavigate();
  const [vinVerified, setVinVerified] = useState(false);
  const [vehicleData, setVehicleData] = useState(null);

  useEffect(() => {
    resetCustomer();
  }, []);

  useEffect(() => {
    checkPermission(user, navigate);
  }, []);

  const checkVin = (e) => {
    if (!isEmpty(e)) {
      setLoader(true);
      callFBFunction('checkVin', {
        vin: e.vin
      }).then(({ data: response }) => {
        setLoader(false);
        const { is_valid, ...rest } = response.data;
        if (is_valid) {
          setVinVerified(true);
          setVehicleData(rest);
        } else {
          setVinVerified(false);
          setVehicleData({});
          setNotify({ message: 'Incorrect VIN number', type: 'error' });
        }
      });
    }
  };

  const onSubmit = (formData, form) => {
    setCustomer({
      ...customer,
      vin: vehicleData.vin,
      make: vehicleData.make,
      model: vehicleData.model,
      year: vehicleData.year
    });
    navigate('/select-photo', {
      replace: true
    });
    form.reset();
  };

  return (
    <>
      <div className="relative w-full max-w-screen-md mx-auto">
        <Header title="New Inventory" subtitle="Add Vehicle" showBack={false} />
        <div className="flex flex-col gap-[30px] pt-6">
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="mx-5 flex flex-col gap-y-[20px]">
                  <div className="flex gap-2 items-end justify-center">
                    <div className="flex-1 flex-col gap-2">
                      <label className="text-base font-medium">VIN</label>
                      <Field
                        type="text"
                        name="vin"
                        component="input"
                        placeholder="1234567890"
                        validate={composeValidators(required)}>
                        {({ input, props }) => (
                          <div>
                            <input
                              {...props}
                              className={`w-full rounded-md border ${
                                vinVerified
                                  ? 'border-[#008000] bg-[#008000] border-opacity-60 bg-opacity-10'
                                  : vehicleData === null
                                  ? 'border-[#555555] border-opacity-40'
                                  : 'border-[#FF0000] bg-[#FF0000] border-opacity-60 bg-opacity-10'
                              } px-4 py-4 focus-visible:outline-none`}
                              onChange={(e) => {
                                input.onChange(e);
                                resetCustomer();
                                setCustomer({
                                  ...customer,
                                  vin: e.target.value
                                });
                                setVinVerified(false);
                              }}
                              value={input.value}
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <button
                      className={`text-[#ffffff] py-4 px-4 rounded-md border ${
                        vinVerified
                          ? 'bg-[#008000] border-[#008000]'
                          : vehicleData === null
                          ? 'bg-[#5046E4] border-[#5046E4]'
                          : 'bg-[#ff0000] border-[#ff0000]'
                      }`}
                      type="button"
                      onClick={() => checkVin(values)}>
                      {vinVerified ? 'Verified' : 'Verify'}
                    </button>
                  </div>
                  {vinVerified && (
                    <div className="border p-2 bg-white rounded-md">
                      <p className="text-xl text-[#333333] font-bold tracking-wide">
                        Vehicle Information
                      </p>
                      <div className="flex flex-col gap-2 mt-2">
                        <p>
                          <b>Year:</b> {vehicleData.year}
                        </p>
                        <p>
                          <b>Make:</b> {vehicleData.make}{' '}
                        </p>
                        <p>
                          <b>Model:</b> {vehicleData.model}
                        </p>
                        <p>
                          <b>Trim:</b> {vehicleData.trim}
                        </p>
                      </div>
                    </div>
                  )}
                  <button
                    type="submit"
                    disabled={!vinVerified}
                    className={`"w-full text-lg text-white ${
                      vinVerified ? 'bg-[#5046E4]' : 'bg-[#E4E7EB]'
                    } rounded-md py-4 mt-2.5"`}>
                    Next Step
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default withUser(withCustomer(withNotify(withLoader(AddVehicle))));
