import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { isEmpty } from 'lodash';
import OTPInput from 'react-otp-input';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { composeValidators, renderField, required } from '../../helpers/form-validations';
import VinsytLogo from '../../assets/image/vinsyt-logo.svg';
import { callFBFunction } from '../../utils/firebase';
import withLoader from '../../redux/Hoc/withLoader';
import withNotify from '../../redux/Hoc/withNotify';
import withChangePassword from '../../redux/Hoc/withChangePassword';

const ChangePassword = ({ setLoader, setNotify, changePassword, resetChangePassword }) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const onSubmit = (formData, form) => {
    setLoader(true);
    const data = {
      email: changePassword.email,
      password: formData.password,
      otp
    };
    callFBFunction('changePassword', data).then(({ data: response }) => {
      const { status, message } = response;
      if (status) {
        setLoader(false);
        setNotify({ message, type: 'success' });
        resetChangePassword();
        form.reset();
        navigate('/login', {
          replace: true
        });
      } else {
        setLoader(false);
        setNotify({ message, type: 'error' });
      }
    });
  };
  return (
    <div className="max-w-screen-md mx-auto">
      <div className="h-[75px] flex flex-row justify-center items-center border-b-[2px] border-[#E4E7EB]">
        <img className="w-[95px] h-auto" src={VinsytLogo} alt="Vinsyt Logo" />
      </div>
      <h1 className="text-2xl font-semibold text-center py-12">Vinsyt Mobile</h1>
      <Form
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          if (!isEmpty(values)) {
            errors.password = undefined;
            errors.cpassword = undefined;

            if (
              !isEmpty(values.password) &&
              !isEmpty(values.cpassword) &&
              values.password !== values.cpassword
            ) {
              errors.cpassword = 'Confrim password is not same as password';
            }
          }
          return errors;
        }}
        render={({ handleSubmit }) => (
          <form className="mx-5 flex flex-col gap-5" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-2">
              <label className="text-base font-medium">OTP</label>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputStyle="customWidth rounded-md border border-[#555555] border-opacity-40 px-4 py-4 focus-visible:outline-none"
                renderSeparator={<span className="p-2">-</span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-base font-medium">Password</label>
              <div className="relative w-100">
                <Field
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  component="input"
                  className="w-[100%] rounded-md border border-[#555555] border-opacity-40 px-4 py-4 focus-visible:outline-none"
                  placeholder="*****"
                  validate={composeValidators(required)}>
                  {renderField}
                </Field>
                <span
                  className="absolute top-5 right-5 cursor-pointer text-[#555555] text-opacity-40"
                  onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <EyeOffIcon width={20} /> : <EyeIcon width={20} />}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-base font-medium">Confirm Password</label>
              <div className="relative w-100">
                <Field
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="cpassword"
                  component="input"
                  className="w-[100%] rounded-md border border-[#555555] border-opacity-40 px-4 py-4 focus-visible:outline-none"
                  placeholder="*****"
                  validate={composeValidators(required)}>
                  {renderField}
                </Field>
                <span
                  className="absolute top-5 right-5 cursor-pointer text-[#555555] text-opacity-40"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {showConfirmPassword ? <EyeOffIcon width={20} /> : <EyeIcon width={20} />}
                </span>
              </div>
            </div>
            <div className="text-right">
              <Link className="text-[#5046E4] font-bold" to="/login">
                Remember Password?
              </Link>
            </div>
            <button
              type="submit"
              className="w-full text-lg text-white bg-[#5046E4] rounded-md py-4 mt-2.5">
              Change Password
            </button>
          </form>
        )}
      />
    </div>
  );
};

export default withNotify(withLoader(withChangePassword(ChangePassword)));
