import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth';
import { getConfig } from '../config';
import { FIREBASE_DOCS } from '../constants';

let app = null;
let functions = null;
let db = null;
let auth = null;

export const initFirebase = () => {
  const config = getConfig();
  app = initializeApp(config.firebaseConfig);
  auth = getAuth(app);
  db = getFirestore(app);
  functions = getFunctions(app);
  // When want to connect with local function.
  // connectFunctionsEmulator(functions, 'localhost', 5001);
};

export const callFBFunction = async (functionName, data) => {
  const functionInstance = httpsCallable(functions, functionName);
  return functionInstance(data);
};

export const doAuthLogin = (token) => signInWithCustomToken(auth, token);
export const doAuthLogout = () => signOut(auth);
export const getCurrentUser = () => auth.currentUser;

export const getAllDealerInventoryByRooftop = async (rooftopId, filter, order) => {
  try {
    const response = [];
    const myQuery = query(
      collection(db, FIREBASE_DOCS.DV_INVENTORY),
      where('rooftop_id', '==', rooftopId),
      orderBy(filter, order ? 'asc' : 'desc')
    );

    const querySnapshot = await getDocs(myQuery);
    if (querySnapshot.size > 0) {
      querySnapshot.forEach((item) => {
        response.push({ id: item.id, ...item.data() });
      });
    }
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return [];
  }
};

export const uploadFile = (path, fileData) =>
  new Promise((resolve, reject) => {
    const contentType = fileData.type;
    const fileExtention = fileData.name.split('.').pop();

    try {
      callFBFunction('generateSignedURL', { path, contentType, fileExtention }).then(
        async (res) => {
          const { url, destination } = res.data.data;
          const upload = await fetch(url, {
            method: 'PUT',
            headers: {
              'Content-Type': contentType // Set the content type of the file
            },
            body: fileData
          });
          if (upload.ok) {
            callFBFunction('generateDownloadURL', { path: destination }).then((downloadURL) => {
              resolve(downloadURL.data.data);
            });
          }
        }
      );
    } catch (error) {
      reject(error);
    }
  });

export const deleteFile = (path) =>
  new Promise((resolve, reject) => {
    try {
      callFBFunction('deleteItem', { path: path.split('/').slice(-2).join('/') }).then((data) => {
        if (data.data.status) {
          resolve(true);
        }
        reject(data.data.message);
      });
    } catch (error) {
      reject(error);
    }
  });
