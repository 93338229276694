import { connect } from 'react-redux';
import { changePasswordSet, changePasswordReset } from '../actions';
import { DEFAULT_CHANGE_PASSWORD } from '../reducers';

const mapDispatchToProps = (dispatch) => ({
  setChangePassword: (data) => {
    dispatch(changePasswordSet(data));
  },
  resetChangePassword: () => {
    dispatch(changePasswordReset());
  }
});

const mapStateToProps = (state) => ({
  changePassword: state.changePassword ? state.changePassword : DEFAULT_CHANGE_PASSWORD
});

export default connect(mapStateToProps, mapDispatchToProps);
