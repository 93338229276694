import React from 'react';
import { Digital } from 'react-activity';

const Loader = () => (
  <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white opacity-75 flex flex-col items-center justify-center">
    <Digital color="#5046E4" size={50} speed={1} animating />
  </div>
);

export default Loader;
