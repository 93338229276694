import React from 'react';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { isEmpty } from 'lodash';
import { persistor, store } from './redux/store';
import Global from './components/Global';

import Login from './screens/auth/Login';
import ChangePassword from './screens/auth/ChangePassword';
import ForgotPassword from './screens/auth/ForgotPassword';

import SelectPhoto from './screens/SelectPhoto';
import Review from './screens/Review';
import Success from './screens/notify/Success';
import CustomerInfo from './screens/CustomerInfo';
import MissingEmail from './screens/notify/MissingEmail';
import Profile from './screens/Profile';
import ReferralInfo from './screens/ReferralInfo';
import Loader from './components/Loader';
import AddVehicle from './screens/dashboard/AddVehicle';
import ChooseVehicle from './screens/dashboard/ChooseVehicle';
import Referrals from './screens/dashboard/Referrals';
import SuccessReferral from './screens/notify/SuccessReferral';
import withSplashScreen from './screens/SplashScreen';
import Sales from './screens/dashboard/Sales';
import SelectVideo from './screens/SelectVideo';
import SuccessTestimonial from './screens/notify/SuccessTestimonial';

const ValidateRoute = ({ children }) => {
  const { user } = store.getState();

  if (!isEmpty(user.id)) {
    return <Navigate to="/inventory" replace />;
  }
  return children;
};

const AppRoutes = () => (
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <Global store={store} />
      <Routes>
        <Route
          path="/login"
          element={
            <ValidateRoute>
              <Login />
            </ValidateRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <ValidateRoute>
              <ForgotPassword />
            </ValidateRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <ValidateRoute>
              <ChangePassword />
            </ValidateRoute>
          }
        />

        <Route path="/inventory" element={<ChooseVehicle />} />
        <Route path="/referrals" element={<Referrals />} />
        <Route path="/add-vehicle" element={<AddVehicle />} />
        <Route path="/owners" element={<Sales />} />

        <Route path="/profile" element={<Profile />} />
        <Route path="/select-photo" element={<SelectPhoto />} />
        <Route path="/select-video" element={<SelectVideo />} />
        <Route path="/review" element={<Review />} />

        {/* Form Screens */}
        <Route path="/customer-info" element={<CustomerInfo />} />
        <Route path="/referral-info" element={<ReferralInfo />} />

        {/* Notify Screens */}
        <Route path="/missing-email" element={<MissingEmail />} />
        <Route path="/success" element={<Success />} />
        <Route path="/success-referral" element={<SuccessReferral />} />
        <Route path="/success-testimonial" element={<SuccessTestimonial />} />

        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </PersistGate>
  </Provider>
);

export default withSplashScreen(AppRoutes);
