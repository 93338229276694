import React from 'react';
import NoVehicleFoundIcon from '../assets/image/icons/no-vehlicle-found-icon.svg';

const NoInformation = ({ title = '', content = '' }) => (
  <div className="flex flex-col h-[calc(100vh-5rem)] mx-5 items-center justify-center">
    <img className="w-16 h-16" src={NoVehicleFoundIcon} alt="success" />
    <div className="text-center text-base text-normal text-[#333333] px-7 pt-10" />
    <h1 className="text-2xl md:text-4xl font-bold text-black text-opacity-90">{title}</h1>
    <p className="text-xs md:text-lg text-center text-black text-opacity-50 md:mt-4">{content}</p>
  </div>
);

export default NoInformation;
