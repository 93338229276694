import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MailIcon, XIcon } from '@heroicons/react/solid';
import Header from '../../components/Header';
import { checkPermission } from '../../helpers/app';
import withUser from '../../redux/Hoc/withUser';

const MissingEmail = ({ user }) => {
  const navigate = useNavigate();

  useEffect(() => {
    checkPermission(user, navigate);
  }, []);

  return (
    <div className="relative w-full max-w-screen-md mx-auto">
      <Header showBack={false} />
      <div className="absolute top-0 min-h-screen flex flex-col flex-1 p-5">
        <div className="flex flex-1 flex-col items-center justify-center">
          <div className="relative">
            <MailIcon className="w-20 h-20 text-[#5046E4]" />
            <XIcon className="absolute top-2 left-0 w-6 h-6 text-red-500 bg-red-300 rounded-full p-1" />
          </div>
          <div className="text-center text-base text-normal text-[#333333] px-7 pt-20">
            <p>Are you sure you want to continue without providing an email?</p>
            <p>
              Communications that keep you and your dealership top of mind will not reach your
              customer, thought they'll still experience vinsyt on the web
            </p>
          </div>
        </div>
        <div className="flex flex-initial flex-col gap-5">
          <button
            type="button"
            className="w-full bg-[#5046E4] rounded-md text-lg text-white py-4 mt-16"
            onClick={() => navigate('/customer-info')}>
            Add Email
          </button>
          <button
            type="button"
            className="w-full text-lg bg-[#E4E7EB] text-black text-opacity-80 rounded-md py-4"
            onClick={() => navigate('/review', { replace: true })}>
            Yes, continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default withUser(MissingEmail);
