import {
  CHANGE_PASSWORD_SET,
  CUSTOMER_SET,
  LOADER_SET,
  NOTIFY_SET,
  REFERRAL_SET,
  RESET_CHANGE_PASSWORD,
  RESET_CUSTOMER,
  RESET_REDUX,
  RESET_REFERRAL,
  RESET_TESTIMONIAL,
  TESTIMONIAL_SET,
  USER_SET
} from './actions';

export const DEFAULT_USER = {
  access_token: '',
  id: '',
  name: { first: '', last: '' },
  email: '',
  avatar: '',
  mobile: '',
  rooftop: '',
  staff: '',
  title: ''
};

export const DEFAULT_CUSTOMER = {
  fname: '',
  lname: '',
  second_lname: '',
  co_buyer_fname: '',
  email: '',
  phone: '',
  vin: '',
  year: '',
  model: '',
  make: '',
  delivery_photo: '',
  social: false,
  dvReceived: false,
  onBoard: false
};

export const DEFAULT_REFERRAL = {
  owner_id: ''
};

export const DEFAULT_TESTIMONIAL = {
  owner_id: ''
};

export const DEFAULT_CHANGE_PASSWORD = {
  email: ''
};

export const loader = (state = false, action) => {
  switch (action.type) {
    case LOADER_SET:
      return action.data;

    default:
      return state;
  }
};

export const notify = (state = {}, action) => {
  switch (action.type) {
    case NOTIFY_SET:
      return action.state;

    default:
      return state;
  }
};

export const user = (state = DEFAULT_USER, action) => {
  switch (action.type) {
    case USER_SET:
      return action.user;
    case RESET_REDUX: {
      const userData = DEFAULT_USER;
      return userData;
    }

    default:
      return state;
  }
};

export const customer = (state = DEFAULT_CUSTOMER, action) => {
  switch (action.type) {
    case CUSTOMER_SET:
      return { ...state, ...action.payload };

    case RESET_CUSTOMER: {
      const customerData = DEFAULT_CUSTOMER;
      return customerData;
    }

    default:
      return state;
  }
};

export const referral = (state = DEFAULT_REFERRAL, action) => {
  switch (action.type) {
    case REFERRAL_SET:
      return { ...state, ...action.payload };

    case RESET_REFERRAL: {
      return DEFAULT_REFERRAL;
    }

    default:
      return state;
  }
};

export const testimonial = (state = DEFAULT_TESTIMONIAL, action) => {
  switch (action.type) {
    case TESTIMONIAL_SET:
      return { ...state, ...action.payload };

    case RESET_TESTIMONIAL: {
      return DEFAULT_TESTIMONIAL;
    }

    default:
      return state;
  }
};

export const changePassword = (state = DEFAULT_CHANGE_PASSWORD, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_SET:
      return { ...state, ...action.payload };

    case RESET_CHANGE_PASSWORD: {
      const changePasswordData = DEFAULT_CHANGE_PASSWORD;
      return changePasswordData;
    }

    default:
      return state;
  }
};
