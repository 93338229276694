import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';

const Profile = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header
        title="Profile"
        subtitle="Update your profile"
        showBack
        showProfile={false}
        onBackClick={() => {
          navigate('/inventory', { replace: true });
        }}
      />
    </>
  );
};

export default Profile;
