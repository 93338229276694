import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { callFBFunction, deleteFile } from '../utils/firebase';
import { checkPermission } from '../helpers/app';
import CloseButton from '../assets/image/icons/close-button.svg';
import Header from '../components/Header';
import Modal from '../components/Modal';
import withCustomer from '../redux/Hoc/withCustomer';
import withLoader from '../redux/Hoc/withLoader';
import withNotify from '../redux/Hoc/withNotify';
import withUser from '../redux/Hoc/withUser';

const Review = ({ user, customer, setCustomer, setNotify, setLoader }) => {
  const navigate = useNavigate();
  const [checkedSocial, setCheckedSocial] = useState(customer.social);
  const [modal, setModal] = useState(false);
  const [modalVehicle, setModalVehicle] = useState(false);

  const submitInformation = () => {
    setLoader(true);
    callFBFunction('customerInfo', { ...customer, social: checkedSocial })
      .then((res) => {
        setLoader(false);
        if (res.data.status) {
          navigate('/success', {
            replace: true
          });
          return;
        }
        setNotify({ message: res.data.message, type: 'error' });
      })
      .catch(() => setLoader(false));
  };

  const deleteImage = () => {
    setLoader(true);
    deleteFile(customer.delivery_photo)
      .then(() => {
        setLoader(false);
        setModal(false);
        setCustomer({ delivery_photo: '' });
      })
      .catch(() => {
        setLoader(false);
        setNotify({ message: 'Something went wrong!', type: 'error' });
      });
  };

  useEffect(() => {
    checkPermission(user, navigate);
  }, []);

  return (
    <>
      <div className="relative w-full max-w-screen-md mx-auto">
        <Header
          title="Submit Vinsyt"
          subtitle="Review the customer information"
          onBackClick={() => navigate('/customer-info', { replace: true })}
        />
        <div className="w-full px-5">
          <div className="flex flex-col gap-5 mb-14 my-6">
            <div className="relative w-full h-[235px] bg-white rounded-md p-2.5">
              {customer.delivery_photo ? (
                <>
                  <button
                    type="button"
                    className="absolute w-5 aspect-square top-1 right-1 z-10"
                    onClick={() => setModal(true)}>
                    <img src={CloseButton} alt="close button" />
                  </button>

                  <img
                    className="relative w-full h-full rounded-md object-center object-cover"
                    src={customer.delivery_photo}
                    alt="delivery"
                  />
                </>
              ) : (
                <div
                  className="flex h-full justify-center items-center"
                  onClick={() => navigate('/select-photo', { replace: true })}>
                  <p className="text-[20px] font-semibold text-[#5046E4]">No Delivery Photo</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mx-5 flex flex-row justify-between items-center">
          <div className="fle flex-col">
            <span className="text-[12px] text-[#6B7280]">Vehicle Details</span>
            <h2 className="text-[16.5px] font-semibold text-[#333333]">
              {customer.year} {customer.make} {customer.model}
            </h2>
            <span className="text-[13px] text-[#333333]">{customer.vin}</span>
          </div>
          <button
            type="button"
            className="text-[14px] text-[#5046E4] font-medium py-3"
            onClick={() => setModalVehicle(true)}>
            Change vehicle
          </button>
        </div>
        <div className="w-full border-b-[2px] border-[#E4E7EB] my-5" />
        <div className="mx-5 flex flex-row justify-between items-end">
          {!isEmpty(customer.fname) ||
          !isEmpty(customer.lname) ||
          !isEmpty(customer.email) ||
          !isEmpty(customer.phone) ? (
            <>
              <div className="flex flex-col">
                <span className="text-[12px] text-[#6B7280]">Customer Information</span>
                <span className="text-[16.5px] font-semibold text-[#333333]">
                  {customer.fname} {customer.lname}
                </span>
                <h2 className="text-[12px] text-[#333333]">{customer.email}</h2>
                <span className="text-[12px] text-[#6B7280]">{customer.phone}</span>
              </div>
              <div className="flex flex-col items-end">
                <button
                  type="button"
                  className="text-[14px] text-[#5046E4] font-medium"
                  onClick={() =>
                    navigate('/customer-info', {
                      replace: true
                    })
                  }>
                  Edit Info
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col">
                <span className="text-[12px] text-[#6B7280]">Customer Information</span>
                <h2 className="text-[16.5px] font-semibold text-[#333333]">Get Automatically</h2>
                <span className="text-[12px] text-[#6B7280]">Usually within 24 hours</span>
              </div>
              <div className="flex flex-col items-end">
                <button
                  type="button"
                  className="text-[14px] text-[#5046E4] font-medium"
                  onClick={() =>
                    navigate('/customer-info', {
                      replace: true
                    })
                  }>
                  Enter Manually
                </button>
                <span className="text-[12px] text-[#6B7280]">Usually within 30 min</span>
              </div>
            </>
          )}
        </div>
        <div className="w-full border-b-[2px] border-[#E4E7EB] my-5" />
        <div className="mx-5 flex flex-row justify-between items-center">
          <div className="flex flex-col gap-2">
            <span className="text-[12.5px] font-normal text-[#6B7280]">Social Permission</span>
            <div className="flex flex-col gap-2.5">
              <div className="flex flex-row gap-2 items-start">
                <input
                  className="mt-[6px] radio-btn-social"
                  type="radio"
                  name="post_type"
                  value="true"
                  id="html"
                  checked={checkedSocial}
                  onChange={() => {
                    setCheckedSocial(true);
                    setCustomer({
                      social: true
                    });
                  }}
                />
                <label
                  className="text-base font-normal text-[#333333] cursor-pointer"
                  htmlFor="html">
                  <h3 className="font-semibold">Post to social media</h3>
                  <span className="text-[12.5px] text-[#6B7280]">
                    Customer permission is required
                  </span>
                </label>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <input
                  className="radio-btn-social"
                  type="radio"
                  name="post_type"
                  value="true"
                  id="css"
                  checked={!checkedSocial}
                  onChange={() => {
                    setCheckedSocial(false);
                    setCustomer({
                      social: false
                    });
                  }}
                />
                <label
                  className="text-base text-[#333333] cursor-pointer font-semibold"
                  htmlFor="css">
                  Do not post to social media
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="px-5">
          <button
            type="button"
            className="bg-[#5046E4] w-full rounded-md text-lg text-white py-4 my-16"
            onClick={submitInformation}>
            Submit
          </button>
        </div>
      </div>
      <Modal
        modal={modal}
        content="Are you sure you want to select a new delivery photo?"
        positiveButton="Yes"
        negativeButton="No, cancel"
        customPadding
        onPositiveClick={() => deleteImage()}
        onNegativeClick={() => setModal(false)}
      />
      <Modal
        modal={modalVehicle}
        content="Are you sure you want to select a new vehicle? You will lose all current progress"
        positiveButton="Yes"
        negativeButton="No, cancel"
        customPadding
        onPositiveClick={() =>
          navigate('/inventory', {
            replace: true
          })
        }
        onNegativeClick={() => setModalVehicle(false)}
      />
    </>
  );
};

export default withNotify(withLoader(withUser(withCustomer(Review))));
