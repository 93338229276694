import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import formatString from 'format-string-by-pattern';
import {
  composeValidators,
  email,
  renderField,
  required,
  usPhoneNumber
} from '../helpers/form-validations';
import Header from '../components/Header';
import { checkPermission } from '../helpers/app';
import withUser from '../redux/Hoc/withUser';
import { callFBFunction } from '../utils/firebase';
import withNotify from '../redux/Hoc/withNotify';
import withLoader from '../redux/Hoc/withLoader';
import withReferral from '../redux/Hoc/withReferral';

const ReferralInfo = ({ user, setLoader, setNotify, referral }) => {
  const navigate = useNavigate();

  const onSubmit = (formData, form) => {
    setLoader(true);
    const data = { userId: referral.owner_id, data: [formData] };
    callFBFunction('sendDiscounts', data).then(({ data: response }) => {
      const { status, message } = response;
      setLoader(false);
      if (status) {
        form.reset();
        setNotify({ message, type: 'success' });
        navigate('/success-referral', {
          replace: true
        });
        return;
      }
      setNotify({ message, type: 'error' });
    });
  };

  useEffect(() => {
    checkPermission(user, navigate);
  }, []);

  return (
    <div className="relative w-full max-w-screen-md mx-auto">
      <Header
        title="Referral"
        subtitle="Add Referral Details"
        onBackClick={() => navigate('/referrals', { replace: true })}
      />
      <div className="flex flex-col gap-[30px] px-5 pt-6">
        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          validate={(values) => {
            const errors = {};
            if (isEmpty(values.phone) || isEmpty(values.email)) {
              if (isEmpty(values.email)) {
                errors.email = 'Required';
              }
              if (isEmpty(values.phone)) {
                errors.phone = 'Required';
              }
            }
            if (!isEmpty(values.email) || !isEmpty(values.phone)) {
              errors.email = undefined;
              errors.phone = undefined;
            }
            return errors;
          }}
          render={({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <div className=" flex flex-col gap-y-5 pb-6">
                <div className="flex flex-col gap-1">
                  <label
                    className={`text-base font-medium ${
                      touched.fname && errors.fname && 'text-red-500'
                    }`}>
                    First Name or Nickname *
                  </label>
                  <Field
                    type="text"
                    name="first"
                    component="input"
                    className={`rounded-md border px-4 py-4 focus-visible:outline-none ${
                      touched.fname && errors.fname
                        ? 'border-red-500 border-opacity-40'
                        : 'border-[#555555] border-opacity-40'
                    }`}
                    showErrorMsg={false}
                    placeholder="James"
                    validate={composeValidators(required)}>
                    {renderField}
                  </Field>
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    className={`text-base font-medium ${
                      touched.lname && errors.lname && 'text-red-500'
                    }`}>
                    Last Name *
                  </label>
                  <Field
                    type="text"
                    name="last"
                    component="input"
                    className={`rounded-md border px-4 py-4 focus-visible:outline-none ${
                      touched.lname && errors.lname
                        ? 'border-red-500 border-opacity-40'
                        : 'border-[#555555] border-opacity-40'
                    }`}
                    showErrorMsg={false}
                    placeholder="Petter"
                    validate={composeValidators(required)}>
                    {renderField}
                  </Field>
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    className={`text-base font-medium ${
                      touched.email && errors.email && 'text-red-500'
                    }`}>
                    Email
                  </label>
                  <Field
                    type="text"
                    name="email"
                    component="input"
                    className={`rounded-md border px-4 py-4 focus-visible:outline-none ${
                      touched.email && errors.email
                        ? 'border-red-500 border-opacity-40'
                        : 'border-[#555555] border-opacity-40'
                    }`}
                    showErrorMsg={false}
                    placeholder="jamespeterleyton54@gmail.com"
                    validate={composeValidators(email)}>
                    {renderField}
                  </Field>
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    className={`text-base font-medium ${
                      touched.phone && errors.phone && 'text-red-500'
                    }`}>
                    Phone
                  </label>
                  <Field
                    type="text"
                    name="phone"
                    component="input"
                    className={`rounded-md border px-4 py-4 focus-visible:outline-none ${
                      touched.phone && errors.phone
                        ? 'border-red-500 border-opacity-40'
                        : 'border-[#555555] border-opacity-40'
                    }`}
                    showErrorMsg={false}
                    placeholder="(402) 555-1212"
                    parse={formatString('(402) 555-1212')}
                    validate={composeValidators(usPhoneNumber)}>
                    {renderField}
                  </Field>
                </div>
                <div className="flex flex-col gap-1">
                  <label
                    className={`text-base font-medium ${
                      touched.relationship && errors.relationship && 'text-red-500'
                    }`}>
                    Relationship
                  </label>
                  <Field
                    type="text"
                    name="relationship"
                    component="input"
                    className={`rounded-md border px-4 py-4 focus-visible:outline-none ${
                      touched.relationship && errors.relationship
                        ? 'border-red-500 border-opacity-40'
                        : 'border-[#555555] border-opacity-40'
                    }`}
                    showErrorMsg={false}
                    placeholder="Brother">
                    {renderField}
                  </Field>
                </div>
                <button
                  type="submit"
                  className="w-full text-lg text-white bg-[#5046E4] rounded-md py-4">
                  Submit
                </button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default withNotify(withLoader(withUser(withReferral(ReferralInfo))));
