import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import withLoader from '../redux/Hoc/withLoader';
import withNotify from '../redux/Hoc/withNotify';
import Loader from './Loader';
import { checkPermission } from '../helpers/app';
import withUser from '../redux/Hoc/withUser';

const Global = ({ loader, notify, user }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (notify) {
      toast(notify.message, {
        type: notify.type,
        icon: true,
        position: 'bottom-center',
        theme: 'colored',
        style: { fontSize: '16px', margin: 10, borderRadius: 10 },
        timeout: 5000
      });
    }
  }, [notify]);

  useEffect(() => {
    checkPermission(user, navigate);
  }, [user]);

  return (
    <>
      <ToastContainer />
      {loader && <Loader />}
    </>
  );
};

export default withLoader(withNotify(withUser(Global)));
