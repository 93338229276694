import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './assets/css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-activity/dist/library.css';
import AppRoutes from './AppRoutes';
import { initFirebase } from './utils/firebase';

initFirebase();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </React.StrictMode>
);
