import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuIcon } from '@heroicons/react/solid';
import BackIcon from '../assets/image/icons/back-arrow-icon.svg';
import withUser from '../redux/Hoc/withUser';
import Drawer from './Drawer';

const Header = ({ user, showBack = true, title, subtitle, showProfile = true, onBackClick }) => {
  const navigate = useNavigate();
  const [drawer, setDrawer] = useState(false);

  return (
    <>
      <div className="sticky top-0 h-[75px] px-5 flex flex-row justify-between items-center border-b-[2px] border-[#E4E7EB] bg-[#5046E4] z-30">
        <div className="flex flex-row gap-5 items-center">
          {showBack ? (
            <div className="flex justify-center items-center text-white" onClick={onBackClick}>
              <img className="w-3 h-auto" src={BackIcon} alt="back" />
            </div>
          ) : (
            <button type="button" className="text-white" onClick={() => setDrawer(true)}>
              <MenuIcon width={30} />
            </button>
          )}
          <div>
            {title && <h1 className="text-[19px] text-white font-semibold">{title}</h1>}
            {subtitle && <h1 className="text-[12px] text-white font-normal">{subtitle}</h1>}
          </div>
        </div>
        <div className="flex items-center justify-center gap-4">
          {showProfile && (
            <button
              type="button"
              className="flex items-center w-9 h-9 justify-center rounded-full shadow-lg overflow-hidden"
              onClick={() => navigate('/profile')}>
              <img className="w-9 h-9" src={user?.avatar} alt="user_profile" />
            </button>
          )}
        </div>
      </div>
      <Drawer isOpen={drawer} setIsOpen={(val) => setDrawer(val)} />
    </>
  );
};

export default withUser(Header);
