import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { composeValidators, renderField, required, email } from '../../helpers/form-validations';
import VinsytLogo from '../../assets/image/vinsyt-logo.svg';
import { callFBFunction } from '../../utils/firebase';
import withLoader from '../../redux/Hoc/withLoader';
import withNotify from '../../redux/Hoc/withNotify';
import withChangePassword from '../../redux/Hoc/withChangePassword';

const ForgotPassword = ({ setLoader, setNotify, setChangePassword }) => {
  const navigate = useNavigate();
  const onSubmit = (formData, form) => {
    setLoader(true);
    callFBFunction('forgotPassword', formData).then(({ data: response }) => {
      const { status, message } = response;
      if (status) {
        setLoader(false);
        setChangePassword(formData);
        setNotify({ message, type: 'success' });
        form.reset();
        navigate('/change-password', {
          replace: true
        });
      } else {
        setLoader(false);
        setNotify({ message, type: 'error' });
      }
    });
  };
  return (
    <div className="max-w-screen-md mx-auto">
      <div className="h-[75px] flex flex-row justify-center items-center border-b-[2px] border-[#E4E7EB]">
        <img className="w-[95px] h-auto" src={VinsytLogo} alt="Vinsyt Logo" />
      </div>
      <h1 className="text-2xl font-semibold text-center py-12">Vinsyt Mobile</h1>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form className="mx-5 flex flex-col gap-5" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-2">
              <label className="text-base font-medium">Email</label>
              <Field
                type="text"
                name="email"
                component="input"
                className="rounded-md border border-[#555555] border-opacity-40 px-4 py-4 focus-visible:outline-none"
                placeholder="Johnny@hometown.com"
                validate={composeValidators(required, email)}>
                {renderField}
              </Field>
            </div>
            <div className="text-right">
              <Link className="text-[#5046E4] font-bold" to="/login">
                Remember Password?
              </Link>
            </div>
            <button
              type="submit"
              className="w-full text-lg text-white bg-[#5046E4] rounded-md py-4 mt-2.5">
              Send OTP
            </button>
          </form>
        )}
      />

      <div className="fixed bottom-5">
        <p className="text-[12px] text-[#6B7280] px-5 mt-4">
          Looking for help? Contact JP Burkemper at{' '}
          <a className="text-[#5046E4]" href="tel:(970) 787-0551">
            (970) 787-0551
          </a>{' '}
          or email{' '}
          <a className="text-[#5046E4]" href="mailto:jp@vinsyt.com">
            jp@vinsyt.com
          </a>
        </p>
        <span className="text-sm text-[#6B7280] mx-5 hidden md:block">
          Download the SA Mobile App for better experience
        </span>
      </div>
    </div>
  );
};

export default withNotify(withLoader(withChangePassword(ForgotPassword)));
