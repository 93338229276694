import React, { useState, useEffect } from 'react';
import logo from '../assets/image/vinsyt-logo.svg';

const SplashMessage = () => (
  <div className="flex items-center justify-center h-screen p-10">
    <img src={logo} className="w-full" alt="logo" />
  </div>
);

const withSplashScreen = (WrappedComponent) => (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (loading) return <SplashMessage />;
  return <WrappedComponent {...props} />;
};

export default withSplashScreen;
